import React, { useState, useEffect } from "react";
import { PAMV1RestUrl } from "../../constants/PatientManagementConstants";
import fetchHelper from "../../services/fetchHelper";
import { LocationMetaDropdown, LocationMetaInput } from "./LocationInformationHandler";

export const FHIRIntegrationSettings = ({ locationMetas, onChangeLocationMeta }) => {
  const [environments, setEnvironments] = useState([]);

  useEffect(() => {
    const fetchEnvironments = async () => {
      try {
        const data = await fetchHelper.get({
          url: `${PAMV1RestUrl}/fhir/environments`,
        });
        setEnvironments(data?.environments);
      } catch (err) {
        console.log(err);
      }
    };
    fetchEnvironments();
  }, []);

  return (
    <>
      <LocationMetaDropdown
        label="FHIR Environment"
        metaKey="pam.psync.fhir.environment"
        metas={locationMetas}
        optionList={environments}
        tag="FHIR"
        onChange={onChangeLocationMeta}
      />
      <LocationMetaInput
        label="FHIR Destination Slug"
        tag="FHIR"
        type="text"
        metaKey="pam.psync.fhir.destinationSlug"
        metas={locationMetas}
        onChange={onChangeLocationMeta}
      />
      <LocationMetaInput
        label="Connection Source ID"
        tag="FHIR"
        type="text"
        metaKey="pam.psync.fhir.sourceId"
        metas={locationMetas}
        onChange={onChangeLocationMeta}
      />
      <LocationMetaInput
        label="FHIR Patient ID Type"
        metaKey="pam.psync.fhir.patientIdType"
        tag="FHIR"
        type="text"
        metas={locationMetas}
        onChange={onChangeLocationMeta}
      />
    </>
  );
};
