// Parameters collected from GenAPI
const usPhoneNumberRegExCol = [/\(\d{3}\)\d{3}-\d{4}/, /\(\d{3}\)\s\d{3}-\d{4}/];
const usPhoneNumberInvalidMinLength = 6;
const usPhoneNumberInvalidMaxLength = 12;

export const PhoneType = {
  Home: { value: "HOME", label: "Home" },
  Mobile: { value: "MOBILE", label: "Mobile" },
  Other: { value: "OTHER", label: "Other" },
};

export const isValidUSPhoneNumber = (value) => {
  const numericValue = value.replace(/\s/g, "").replace(/[^\d]+/g, "");
  if (
    numericValue.length > usPhoneNumberInvalidMinLength &&
    numericValue.length < usPhoneNumberInvalidMaxLength
  ) {
    return usPhoneNumberRegExCol.some((exp) => !!exp.test(value));
  }

  return false;
};
