/**
 * @todo: refactor this store
 */
import { combineReducers, createStore, applyMiddleware, compose } from "redux";

// ACTION \/
const actionTypes = {
  LOGIN: "LOGIN",
  VERIFY: "VERIFY",
  REGISTER: "REGISTER",
  SET_AUTH_DATA: "SET_AUTH_DATA",
  SET_CURRENT_IR: "SET_CURRENT_INTERROGATION",
  SET_FEATURE_FLAGS: "SET_FEATURE_FLAGS",
};

const actionBuilder = (type, ...argNames) => {
  return function (...args) {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
    });
    return action;
  };
};
export const login = actionBuilder(actionTypes.LOGIN, "payload");
export const verify = actionBuilder(actionTypes.VERIFY, "payload");
export const register = actionBuilder(actionTypes.REGISTER, "payload");
export const setAuthData = actionBuilder(actionTypes.SET_AUTH_DATA, "payload");
export const setCurrentIRData = actionBuilder(actionTypes.SET_CURRENT_IR, "payload");
export const setFeatureFlags = actionBuilder(actionTypes.SET_FEATURE_FLAGS, "payload");

// REDUCER \/
const initialState = { email: "", password: "" };
export const panelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH_DATA:
      return { ...state, email: action.payload.email, password: action.payload.password };
    default:
      return state;
  }
};

export const currentIR = (state = {}, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_CURRENT_IR:
      return { ...state, patient: { id: payload.patient.id } };
    default:
      return state;
  }
};

export const loadingStates = {
  NONE: "NONE",
  LOADING: "LOADING",
  DONE: "DONE",
};

export const feaureFlags = (state = { data: [], loadingState: loadingStates.NONE }, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_FEATURE_FLAGS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

// STORE \/
const middleware = [];
export const store = createStore(
  combineReducers({ auth: panelReducer, currentIR, feaureFlags }),
  undefined,
  compose(applyMiddleware(...middleware))
);

export const initializeStore = (initialState = undefined) => store;
