import React from "react";
import { LocationMetaInput } from "./LocationInformationHandler";

export const RLSIntegrationSettings = ({ locationMetas, onChangeLocationMeta }) => (
  <>
    <LocationMetaInput
      label="RLS Destination ID"
      metaKey="DestinationId"
      tag="RLS"
      type="text"
      metas={locationMetas}
      onChange={onChangeLocationMeta}
    />
    <LocationMetaInput
      label="RLS Destination Name"
      metaKey="DestinationName"
      tag="RLS"
      type="text"
      metas={locationMetas}
      onChange={onChangeLocationMeta}
    />
    <LocationMetaInput
      label="Patient ID Type"
      metaKey="PatientIdType"
      tag="GENERIC"
      type="text"
      metas={locationMetas}
      onChange={onChangeLocationMeta}
      placeholder="MRN"
    />
  </>
);
