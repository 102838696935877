import produce from "immer";
import React, { useState, useEffect, useMemo, useContext } from "react";
import { DebounceInput } from "react-debounce-input";
import { PatientsContext } from "../pages/patients";
import { PhoneType, isValidUSPhoneNumber } from "../constants/PhoneType";
import { otherPhoneWarning } from "../constants/WarningMessages";

const DefaultPreferredPhoneType = PhoneType.Home;

const getPhoneTypeFromValue = (value) => Object.values(PhoneType).find((it) => it.value == value);

const PreferredPhoneField = ({ data, setter }) => {
  const { information } = useContext(PatientsContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneType, setPhoneType] = useState(DefaultPreferredPhoneType);

  const isFieldDisabled = useMemo(() => !(information.openForm && !information.loading), [information]);

  const isInputDisabled = useMemo(
    () => isFieldDisabled || phoneType != PhoneType.Other,
    [phoneType, isFieldDisabled]
  );

  const invalidNumberFlag = useMemo(
    () => phoneType == PhoneType.Other && phoneNumber != "" && !isValidUSPhoneNumber(phoneNumber),
    [phoneNumber]
  );

  useEffect(() => {
    setPhoneType(getPhoneTypeFromValue(data.preferredPhoneType) || DefaultPreferredPhoneType);
  }, [data]);

  useEffect(() => {
    let number = "";
    switch (phoneType) {
      case PhoneType.Home:
        number = data.homePhone;
        break;

      case PhoneType.Mobile:
        number = data.mobilePhone;
        break;

      case PhoneType.Other:
        number = data.otherPhone;
        break;
    }
    setPhoneNumber(number || "");
  }, [data, phoneType]);

  const onInputChange = (e) => {
    const { value } = e.target;
    if (phoneType == PhoneType.Other) {
      setter((prev) =>
        produce(prev, (draft) => {
          draft.otherPhone = value;
        })
      );
    }
  };

  const onDropdownChange = (e) => {
    const { value } = e.target;
    setter((prev) =>
      produce(prev, (draft) => {
        draft.preferredPhoneType = value;
      })
    );
  };

  return (
    <div className="preferred-number-main">
      <div data-testid="preferred-number-container" className="preferred-number-container">
        <select
          data-testid="preferred-number"
          id="preferred-number-select"
          className="preferred-number"
          onChange={onDropdownChange}
          value={phoneType.value}
          disabled={isFieldDisabled}
        >
          {Object.values(PhoneType).map(({ label, value }, i) => (
            <option key={i} value={value}>
              {label}
            </option>
          ))}
        </select>
        <div className="vertical-line"></div>
        <DebounceInput
          type="tel"
          data-testid="other-number-input"
          id="other-phone-input"
          debounceTimeout={250}
          value={phoneNumber}
          onChange={onInputChange}
          disabled={isInputDisabled}
        />
      </div>
      {invalidNumberFlag && <small className="input-not-valid">{otherPhoneWarning}</small>}
    </div>
  );
};
export default PreferredPhoneField;
