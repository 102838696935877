import React from "react";
import Select from "react-select";

import "./multiSelect.scss";

const customMultiSelectStyles = {
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 8px",
  }),

  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    cursor: "pointer",
  }),
};

const MultiSelectV2 = (props) => {
  const { isMulti, value, onChange, name: dataKey } = props;

  const handleRemoveValue = (e) => {
    if (!onChange) return;
    const {
      name: buttonName,
      dataset: { key },
    } = e.currentTarget;
    const removedValue = value.find((val) => val.value === buttonName);

    if (!removedValue) return;

    onChange(
      value.filter((val) => val.value !== buttonName),
      { action: "remove-value", removedValue: { ...removedValue }, name: key }
    );
  };

  return (
    <div className="multi-select-container">
      <Select styles={customMultiSelectStyles} {...props} controlShouldRenderValue={!isMulti} />

      {isMulti
        ? value.map((val) => (
            <div className="items-selected" key={val.value}>
              <div className="select__multi-value__label">{val.label}</div>
              <button
                className="select__multi-value__remove"
                name={val.value}
                onClick={handleRemoveValue}
                data-key={dataKey}
              >
                ✕
              </button>
            </div>
          ))
        : null}
    </div>
  );
};

export default MultiSelectV2;
