import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFeatureFlags, loadingStates } from "../auth/store";
import fetchHelper from "../services/fetchHelper";

const defaultValue = { enabled: false, attributes: {} };

const useFeatureFlag = () => {
  const dispatch = useDispatch();

  const { data: featureFlags, loadingState } = useSelector((state) => ({
    data: state.feaureFlags.data,
    loadingState: state.feaureFlags.loadingState,
  }));

  const fetchData = async () => {
    const featureFlagResponse = await fetchHelper.get({
      url: "/patientmanagement/rest/feature-flags",
    });
    dispatch(setFeatureFlags({ data: featureFlagResponse, loadingState: loadingStates.DONE }));
  };

  useEffect(() => {
    if (loadingState === loadingStates.NONE && (Boolean(featureFlags) || featureFlags.length === 0)) {
      dispatch(setFeatureFlags({ loadingState: loadingStates.LOADING }));
      fetchData();
    }
  }, [loadingState, featureFlags]);

  const getFlag = (flagKey) => {
    let flag = featureFlags.filter((f) => f.featureName === flagKey);
    return flag[0] || defaultValue;
  };

  const featureCheck = (flagKey) => {
    return getFlag(flagKey).enabled;
  };

  /**
   * Check if a feature flag for locations is enable in the current location
   * @param {*} featureFlag: Feature flag name
   * @param {*} attribute: Attribute to validate if feature flag is enable
   * @param {*} attributeLocations: Locations that should be active
   * @param {*} locationID: Current user location
   * @returns boolean
   */
  const isFeatureForLocationEnable = (featureFlag, attribute, attributeLocations, locationID) => {
    const featureFlagData = getFlag(featureFlag);
    if (featureFlagData.enabled && featureFlagData.attributes[attribute]) {
      const allLocationEnable = featureFlagData.attributes[attributeLocations] || [];
      return allLocationEnable.length === 0 || allLocationEnable.includes(locationID);
    }
    return false;
  };

  const isLoadCompleted = loadingState === loadingStates.DONE;

  return { getFlag, featureCheck, featureFlags, isLoadCompleted, isFeatureForLocationEnable };
};

export default useFeatureFlag;
